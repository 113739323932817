import { useMutation } from "@tanstack/react-query";
import { post } from "api/api-methods";
import { BackFromPaymentTerminalParameters, WebBooking } from "hooks/api/types";
import { trackEvent } from "logging/insights";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { useNavigate, useParams } from "react-router-dom";
import { GUID_STORAGE_KEY } from "store";
import { State } from "types/store";

interface ApiResponse {
    result: WebBooking | null;
    validationResult: ValidationResult;
}

export const useBackFromPaymentTerminal = () => {
    const { system } = useParams();
    const navigate = useNavigate();

    const backFromPaymentTerminal = async ({
        state,
        chargeId,
        offerStartTime,
    }: {
        state: State;
        chargeId: string;
        offerStartTime: string | null;
    }) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        const data: BackFromPaymentTerminalParameters = {
            ...state,
            logMessage: "Bekräftar betalterminal",
            bookingChargeId: chargeId,
            startTime: offerStartTime,
        };

        let eventName = "BackFromPaymentTerminal";
        let response: ApiResponse;

        const startTime = new Date().getTime();

        try {
            response = await post<ApiResponse, BackFromPaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/BackFromPaymentTerminal`,
                data
            );
            localStorage.removeItem(GUID_STORAGE_KEY);
            navigate(`/booking/${system}/${guid}`, {
                state: { isFinal: true },
            });
        } catch (error) {
            localStorage.removeItem(GUID_STORAGE_KEY);
            eventName = "BackFromPaymentTerminalFailed";

            navigate(`/booking/${system}/${guid}`, {
                state: { isFinal: true, paymentError: true },
            });
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;

        const { guests, guestsChildren, start } = response.result;
        trackEvent(eventName, {
            guests: guests,
            children: guestsChildren,
            date: start.toISOString(),
            ms: loadTime,
        });

        return response;
    };

    return useMutation({
        mutationFn: backFromPaymentTerminal,
        scope: {
            id: "webbooking-create",
        },
    });
};
