import { useMutation, useQuery } from "@tanstack/react-query";
import { genericGet, post } from "api/api-methods";
import { AxiosError, AxiosResponse } from "axios";
import i18n from "config/i18n";
import { useError } from "context/error-context";
import { WebBooking } from "hooks/api/types";
import queryClient from "query-client";
import { useTranslation } from "react-i18next";
import { GUID_STORAGE_KEY } from "store";
import { State, Time } from "types/store";
import { CreateBookingParameters } from "./types";

export interface WebBookingErrorData {
    code: number;
    message: string;
}
export interface CreateBookingAxiosError extends AxiosError {
    response?: AxiosResponse<WebBookingErrorData>;
}

export function useReservation() {
    const id = localStorage.getItem(GUID_STORAGE_KEY);
    return useQuery({
        queryKey: ["booking", id],
        queryFn: async () => {
            return genericGet(`webBooking/webBookings/${id}`).then((response: WebBooking) => {
                return response;
            });
        },
        enabled: !!id,
    });
}

export const useCreateReservation = () => {
    const { t } = useTranslation();
    const { setErrorCode, openReservationFailedModal } = useError();

    const createReservation = async (state: State) => {
        const data: CreateBookingParameters = {
            ...state,
            guestsChildren: state.guestsChildren || 0,
            times: state.times.map((time: Time) => {
                return {
                    ruleId: time.ruleId,
                    start: time.start.format("YYYY-MM-DD[T]HH:mm:ss"),
                };
            }),
            timeSetDate: state.date.format("YYYY-MM-DD[T]HH:mm:ss"),
            lang: i18n.language,
            isPending: true,
            isWaitList: state.isWaitList ? true : false,
            logMessage: t("changeLogMessage.pendingLogMessage"),
        };

        let booking: WebBooking;

        try {
            booking = await post<WebBooking, CreateBookingParameters>(
                `WebBooking/WebBookings`,
                data
            );
            localStorage.setItem(GUID_STORAGE_KEY, booking.guid);
            queryClient.setQueryData<WebBooking>(["booking", booking.guid], booking);
        } catch (error) {
            console.error(error);
            const errorCode = error?.response.data.code;
            if (typeof errorCode === "number") {
                setErrorCode(errorCode);
                openReservationFailedModal();
            }
        }

        return booking;
    };

    return useMutation({
        mutationFn: createReservation,
        scope: {
            id: "webbooking-create",
        },
    });
};
