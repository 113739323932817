import { useMutation } from "@tanstack/react-query";
import { useWebBooking } from "api/api-hooks";
import { post } from "api/api-methods";
import { BookingStatus } from "api/models/bookingStatus";
import i18n from "config/i18n";
import { PaymentTerminalParameters } from "hooks/api/types";
import { trackEvent } from "logging/insights";
import { useParams } from "react-router";
import { GUID_STORAGE_KEY } from "store";
import { State } from "types/store";
import { getAvailableWaitListOffer } from "utils/booking";

export const usePaymentTerminal = () => {
    const paymentTerminal = async (state: State) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        const returnUrl = `${document.location.origin + document.location.pathname}/payment-confirmation${document.location.search}`;
        const data: PaymentTerminalParameters = {
            ...state,
            lang: i18n.language,
            logMessage: "Betalning påbörjad",
            message: state.contact?.message,
            terminalReturnUrl: returnUrl,
            payMethod: state.payment ? state.payment.payMethod : null,
        };

        let eventName = "PaymentTerminal";
        let terminalUrl: string;

        const startTime = new Date().getTime();
        try {
            terminalUrl = await post<string, PaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/PaymentTerminal`,
                data
            );
            window.location.replace(terminalUrl);
        } catch (error) {
            eventName = "PaymentTerminalFailed";
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });

        return terminalUrl;
    };

    return useMutation({
        mutationFn: paymentTerminal,
        scope: {
            id: "webbooking-create",
        },
    });
};

export const usePaymentTerminalWaitList = () => {
    const { data: webBooking } = useWebBooking();
    const { system } = useParams();
    const paymentTerminal = async (state: State) => {
        const guid = webBooking.guid;
        let returnUrl = `${document.location.origin}/${system}/${webBooking.unitId}/payment-confirmation${document.location.search}`;

        const offerWaitListStartTime = getAvailableWaitListOffer(webBooking).startTime;
        returnUrl = `${returnUrl}?offerStartTime=${offerWaitListStartTime?.format("YYYY-MM-DD[T]HH:mm:ss")}`;

        const data: PaymentTerminalParameters = {
            ...state,
            lang: i18n.language,
            logMessage: "Betalning påbörjad",
            terminalReturnUrl: returnUrl,
            payMethod: state.payment.payMethod,
            contact: webBooking.contact,
        };

        let eventName = "PaymentTerminal";
        let terminalUrl: string;

        const startTime = new Date().getTime();
        try {
            terminalUrl = await post<string, PaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/PaymentTerminal`,
                data
            );
            window.location.replace(terminalUrl);
        } catch (error) {
            eventName = "PaymentTerminalFailed";
            console.error(error);
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;

        trackEvent(eventName, {
            guests: webBooking.guests,
            children: webBooking.guestsChildren,
            date: webBooking.start.toISOString(),
            isWaitingList: true,
            ms: loadTime,
        });

        return terminalUrl;
    };

    return useMutation({
        mutationFn: paymentTerminal,
        scope: {
            id: "webbooking-create",
        },
    });
};

export const usePaymentTerminalPaymentLink = () => {
    const { data: webBooking } = useWebBooking();
    const { system, bookingId } = useParams();
    const paymentTerminal = async (state: State) => {
        const returnUrl = `${document.location.origin}/${system}/${webBooking.unitId}/payment-confirmation`;
        const data: PaymentTerminalParameters = {
            ...state,
            lang: i18n.language,
            logMessage: "Betalning påbörjad",
            terminalReturnUrl: returnUrl,
            payMethod: null,
            contact: webBooking.contact,
        };

        let eventName = "PaymentTerminal";
        let terminalUrl: string;

        const startTime = new Date().getTime();
        try {
            terminalUrl = await post<string, PaymentTerminalParameters>(
                `WebBooking/WebBookings/${bookingId}/PaymentTerminal`,
                data
            );
            window.location.replace(terminalUrl);
        } catch (error) {
            eventName = "PaymentTerminalFailed";
            console.error(error);
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;

        trackEvent(eventName, {
            guests: webBooking.guests,
            children: webBooking.guestsChildren,
            date: webBooking.start.toISOString(),
            isWaitingList: webBooking.status === BookingStatus.WaitList,
            ms: loadTime,
        });

        return terminalUrl;
    };

    return useMutation({
        mutationFn: paymentTerminal,
        scope: {
            id: "webbooking-create",
        },
    });
};
