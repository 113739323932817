import { Button, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { ConfirmPaymentForm, PaymentMethodField } from "components/payment-field/payment-field";
import { getDefaultPaymentMethod_Temporary } from "helpers/payment-helper";
import { useConfirmWaitListBooking, useExternalBookingSettings, useWebBooking } from "hooks";
import { useExternalBookingSettings_Not_Immutable } from "hooks/api/external-booking-settings-not-immutable";
import {
    usePaymentTerminalPaymentLink,
    usePaymentTerminalWaitList,
} from "hooks/api/payment-terminal";
import { ConditionRulePayment as paymentType } from "old/models/webRuleConditionRulePaymentEnum";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GUID_STORAGE_KEY, useBookingStateContext } from "store";
import { getUrlParams } from "utils";
import {
    hasUnhandledNoShowLink,
    hasUnhandledPaymentEventLink,
    hasUnpaidPrepayment,
    isNoShowLink,
    isPaymentEventExpired,
    isWaitList,
} from "utils/booking";
import { getPaymentCalculationTotal } from "utils/payment-utils";

const BookingActionButton = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { mutate: confirmMutate, isPending: confirmIsLoading } = useConfirmWaitListBooking();
    const terminalWaitList = usePaymentTerminalWaitList();
    const terminal = usePaymentTerminalPaymentLink();
    const { paymentLink } = getUrlParams();

    const state = useBookingStateContext();

    const externalBookingSettings = useExternalBookingSettings();
    const { data: settings } = useExternalBookingSettings_Not_Immutable();

    const rule = webBooking?.webTimeRules?.first();
    const isPrepayment =
        (rule && rule.didShowUpRuleType === paymentType.PrePayment) ||
        hasUnpaidPrepayment(webBooking);
    const { control, watch } = useForm<ConfirmPaymentForm>({
        mode: "onChange",
        defaultValues: {
            payMethod: getDefaultPaymentMethod_Temporary(
                externalBookingSettings,
                isPrepayment,
                state
            ),
        },
        reValidateMode: "onChange",
    });

    const availablePaymentMethods = settings?.paymentMethods;

    const isPaymentLink = Boolean(paymentLink);
    const hasLinkPassed = isPaymentEventExpired(webBooking, isPaymentLink);

    const ruleTotal = getPaymentCalculationTotal(
        rule as any,
        webBooking.guests,
        webBooking?.articles?.filter((x) => x.isTable) as any
    );

    const linkTotal = webBooking.paymentEvents.last()?.requestedAmount;

    const cardRegistration =
        (rule && rule.didShowUpRuleType === paymentType.NoShowFee) ||
        hasUnhandledNoShowLink(webBooking);

    const getButtonText = () => {
        if (isPrepayment) {
            return t("payAmount", {
                amount: t("currency.onlyAmount", {
                    value: ruleTotal || linkTotal,
                }),
            });
        }
        if (cardRegistration) {
            return t("registerPaymentCard");
        }
        return t("waitList.confirmBooking");
    };

    const isWaitListBooking = isWaitList(webBooking);
    const onActionClick = () => {
        if (hasUnhandledPaymentEventLink(webBooking, isPaymentLink) && !isWaitListBooking) {
            localStorage.setItem(GUID_STORAGE_KEY, webBooking.guid);
            terminal.mutate({});
        } else if (
            rule &&
            isWaitListBooking &&
            (rule.didShowUpRuleType === paymentType.PrePayment ||
                rule.didShowUpRuleType === paymentType.NoShowFee)
        ) {
            terminalWaitList.mutate({ payment: { payMethod: watch("payMethod") } });
        } else {
            confirmMutate();
        }
    };

    const isNoShowPaymentLink = isNoShowLink(webBooking);
    const linkExpirationDate =
        webBooking.chargePaymentLinkExpirationDate?.format("YYYY-MM-DD HH:mm");

    const isArray = Array.isArray(availablePaymentMethods)
        ? availablePaymentMethods.length
        : //@ts-expect-error In a transtion period.
          availablePaymentMethods?.count();

    const isPaymentMethodFieldVisible = isArray > 1 && isPrepayment;
    return (
        <>
            {isPaymentMethodFieldVisible && <PaymentMethodField control={control} />}
            <Stack spacing={ThemeSpaceVariable.Small}>
                <Button
                    variant="primary"
                    isLoading={confirmIsLoading || terminalWaitList.isPending || terminal.isPending}
                    onClick={onActionClick}
                    isDisabled={hasLinkPassed}
                    size="lg"
                >
                    {getButtonText()}
                </Button>
                {hasUnhandledPaymentEventLink(webBooking, isPaymentLink) &&
                    !isWaitListBooking &&
                    linkExpirationDate && (
                        <Text align="center" color={ThemeColorVariable.OnSurfaceSubdued}>
                            {isNoShowPaymentLink
                                ? t("payment.cardRegistrationPossibleTo", {
                                      date: linkExpirationDate,
                                  })
                                : t("payment.prepaymentPossibleTo", { date: linkExpirationDate })}
                        </Text>
                    )}
            </Stack>
        </>
    );
};

export default BookingActionButton;
