import { useQuery } from "@tanstack/react-query";
import moment from "moment-timezone";
import { Moment } from "moment/moment";
import qs from "qs";
import { useParams } from "react-router-dom";
import { useBookingStateContext } from "store";
import { dateToString, getUrlParams } from "utils";
import { get } from "./axios-base";
import { AvailableTimesUnit } from "./types";

interface AvailableTimesParams {
    date: string;
    unitId: string;
    sectionId?: number[];
}

export function useAvailableTimes(date: Moment) {
    const { unitId } = useParams();
    const { sectionIds } = getUrlParams();
    const { guests } = useBookingStateContext();
    const params: AvailableTimesParams = {
        date: dateToString(date),
        unitId,
    };

    if (sectionIds) {
        params.sectionId = sectionIds;
    }

    return useQuery({
        queryKey: ["availableTimes", date?.format("YYYY-MM-DD")],
        queryFn: async () => {
            const response = await get(`/WebBooking/availableTimes`, {
                params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
                transformResponse: (data) => {
                    const units: AvailableTimesUnit[] = JSON.parse(data);
                    const now = moment();
                    units.forEach((unit) => {
                        unit.sections.forEach((section) => {
                            section.timeSets.forEach((timeSet) => {
                                timeSet.times.forEach((time) => {
                                    time.ruleId = timeSet.id;
                                    time.availableUntil = moment(
                                        time.availableUntil,
                                        "YYYY-MM-DDTHH:mm:ss"
                                    );
                                    time.start = moment(time.start, "YYYY-MM-DDTHH:mm:ss");
                                    time.end = moment(time.end, "YYYY-MM-DDTHH:mm:ss");

                                    const isTimeExpired = now.isAfter(time.availableUntil);
                                    const isSeatsUnavailable =
                                        !time.availableSeats.includes(guests);
                                    const isWaitListUnavailable =
                                        !time.waitListSeats?.includes(guests);

                                    if (
                                        isTimeExpired ||
                                        (isSeatsUnavailable && isWaitListUnavailable)
                                    ) {
                                        time.disabled = true;
                                    }
                                });
                            });
                        });
                    });
                    return units;
                },
            });
            return response.data as AvailableTimesUnit[];
        },
        enabled: !!date,
    });
}

export function useFilteredAvailableTimes(date: Moment) {
    const { data: availableTimesData, isLoading, isError } = useAvailableTimes(date);

    if (isLoading || isError) return [];

    if (!Array.isArray(availableTimesData) || availableTimesData.length === 0) {
        return [];
    }

    const availableTimesCopy = { ...availableTimesData[0] };
    const availableTimes = availableTimesCopy.sections;
    availableTimes.forEach((section) => {
        section.timeSets.forEach((timeSet) => {
            timeSet.times = timeSet.times.filter((time) => !time.disabled);
        });
    });

    return availableTimes;
}
