import i18n from "config/i18n";

export enum SupportedLanguage {
    EN = "en-US",
    SV = "sv-SE",
    NB = "nb-NO",
    DA = "da-DK",
    FI = "fi-FI",
    ES = "es-ES",
    ET = "et-EE",
    RU = "ru-RU",
    FR = "fr-BE",
    NL = "nl-BE",
}

enum Lang {
    en = "en",
    no = "nb",
    da = "da",
    sv = "sv",
}
enum Locale {
    en = "en-GB",
    no = "nb-NO",
    da = "da-DK",
    sv = "sv-SE",
}
export const getLocaleFromLang = (langStr: string): Locale => {
    if (langStr.includes(Lang.en)) {
        return Locale.en;
    }
    if (langStr.includes(Lang.no)) {
        return Locale.no;
    }
    if (langStr.includes(Lang.da)) {
        return Locale.da;
    }
    if (langStr.includes(Lang.sv)) {
        return Locale.sv;
    }
    return Locale.en; // Default to en-GB if no match is found
};

/**
 * Helper to get translated name of current used i18n language.
 * @returns translated name of current language.
 * For ex. i18n language is "SV" the return value will be "Svenska".
 */
export const getNameOfCurrentLang = () => {
    switch (i18n.language) {
        case SupportedLanguage.EN:
            return i18n.t("miscEnglish");
        case SupportedLanguage.SV:
            return i18n.t("miscSwedish");
        case SupportedLanguage.NB:
            return i18n.t("miscNorwegian");
        case SupportedLanguage.DA:
            return i18n.t("miscDanish");
        case SupportedLanguage.FI:
            return i18n.t("miscFinnish");
        default:
            return i18n.t("miscEnglish"); // default to English if no match
    }
};

export default getNameOfCurrentLang;
